<!--
 * @Author: yanzq
 * @Date: 2021-12-17 23:50:22
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-01-08 17:42:23
 * @Description: 请填写简介
-->
<template>
  <div class="uesrCenter">
    <div class="banner flex-y-s">
      <a href="javasctipt:;" @click="backLevel" class="backBtn">返回</a>
      <a class="homeBtn" @click="backHome" href="javascript:;">首页</a>
      <div style="position: relative; top: -20px">
        <!-- <h3>名称名称</h3> -->
        <p>欢迎您！{{ $store.state.globalInfo.nickname }}</p>
      </div>
    </div>
    <div class="box-swipe">
      <van-swipe class="my-swipe" indicator-color="#2D90FB" :width="370">
        <van-swipe-item v-for="(item, i) in resultData" :key="i">
          <div class="card">
            <div>
              <div class="select-user-header">
                <img
                  width="17px"
                  style="position: relative; top: 2px"
                  src="../../assets/images/userCenter/title-icon.png"
                  alt=""
                />
                {{ item.title }}
              </div>
              <div v-for="(itt, index1) in item.nameList" :key="index1">
                <div class="select-user">
                  <span>{{ itt.title }}</span>
                  <span class="blue-font" @click="add(itt)"> 添加 + </span>
                </div>
                <div>
                  <div class="select-group" v-if="itt.subList.length">
                    <div
                      class="select-user-item"
                      v-for="(items, index) in itt.subList"
                      :key="index"
                    >
                      <div class="tx">
                        <img :src="items.waHeadImg" alt="" />
                        <span v-if="items.waBind == '1'" class="bind">
                          <img
                            src="../../assets/images/userCenter/y.png"
                            alt=""
                          />
                        </span>
                        <span v-else class="no-bind">
                          <img
                            src="../../assets/images/userCenter/n.png"
                            alt=""
                          />
                        </span>
                      </div>
                      <p class="van-ellipsis">{{ items.waAccountName }}</p>
                    </div>
                  </div>
                  <div v-else style="text-align: center">
                    <img
                      style="width: 80px; height: 60px"
                      src="../../assets/images/userCenter/nodata.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>

    <!-- 手机号弹窗 -->
    <!-- <van-dialog
      v-model="show1"
      title="请输入手机号"
      show-cancel-button
      @cancel="show1 = false"
      @confirm="confirm"
    >
      <template>
        <van-field style="margin: 0 15px" v-model="phone" rows="1" placeholder="请输入留手机号" />
        <van-field style="margin: 0 15px" v-model="name" rows="1" placeholder="请输入姓名" />
      </template>
    </van-dialog> -->
    <van-popup v-model="show1" style="width: 85%">
      <div
        class="banner pop-banner"
        style="height: 80px; justify-content: center"
      >
        <div class="title">{{ "绑定" + title + "群组" }}</div>
        <!-- <img src="../../assets/images/userCenter/edit.png" alt="" />
        <van-field
          v-model="phone"
          required
          name="phone"
          placeholder="请填写手机号"
          :rules="[{ required: true, message: '请填写企业名称' }]"
        /> -->
      </div>

      <van-form @submit="confirm">
        <van-cell-group inset>
          <van-field
            class="flex-block"
            v-model="phone"
            required
            name="phone"
            label="手机号"
            placeholder="请填写手机号"
            :rules="[{ required: true, message: '请填写企业名称' }]"
          />
          <van-field
            class="flex-block"
            required
            v-model="name"
            name="name"
            label="姓名"
            placeholder="请填写姓名"
            :rules="[{ required: true, message: '请填写姓名' }]"
          />
        </van-cell-group>
        <div
          style="margin: 16px; display: flex; justify-content: space-between"
        >
          <van-button
            round
            block
            @click="cancelBtn"
            native-type="button"
            style="
              background: #ffffff;
              border: 2px solid #3295f9;
              color: #3295f9;
              height: 36px;
              width: 46%;
            "
          >
            取消
          </van-button>
          <van-button
            round
            block
            type="info"
            native-type="submit"
            style="
              width: 46%;
              background-image: linear-gradient(
                90deg,
                #2d90fb 0%,
                #84bfff 100%
              );
              height: 36px;
            "
          >
            提交
          </van-button>
        </div>
      </van-form>
    </van-popup>
    <van-dialog
      v-model="show2"
      title="请将链接分享给对应的微信好友"
      @confirm="confirmBtn"
    >
      <div class="link-con">
        <div class="link-txt">{{ sharelink }}</div>

        <div class="link-btn">
          <van-button
            type="primary"
            size="mini"
            v-clipboard:copy="sharelink"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
          >
            复制链接
          </van-button>
        </div>
      </div> </van-dialog
    ><img
      v-show="imgShow"
      class="imgShow"
      @click="imgShow = false"
      src="../../assets/images/tip.png"
      alt=""
    />
    <div class="back" v-show="imgShow" @click="imgShow = false">返回</div>
  </div>
</template>

<script>
import { getGroup, getAccountByOpenId } from "../../api/user";
import { Toast } from "vant";
import {
  checkSign,
  saveAccountByGroup,
  getWechatEnterpriseSupervisionCount,
} from "../../api/home";
export default {
  data() {
    return {
      sharelink:
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx47b46760287ffd31&redirect_uri=",
      classify: {},
      show1: false,
      show2: false,
      phone: "",
      name: "",
      list: [],
      data: {},
      dataInfo: {},
      resultData: [],
      title: "",
      waCompanyName: "",
      info: "",
      waShxydm: "",
      imgShow: false,
    };
  },

  mounted() {
    this.getData();
  },
  created() {},

  methods: {
    backLevel(e) {
      e.preventDefault();
      this.$router.go(-1);
      return false;
    },
    backHome(e) {
      e.preventDefault();
      wx.closeWindow();
      return false;
    },
    //点击头像打开链接
    // openLink(index, el) {
    //   this.show2 = true;
    //   let waShxydm = el.group;
    //   let waGroup = el.subList[index].waShxydm;
    //   this.sharelink =
    //     "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx47b46760287ffd31&redirect_uri=";
    //   this.sharelink = `${this.sharelink}${encodeURIComponent(
    //     `http://qtqyzx.qiantang.gov.cn/#?isShare=true&waShxydm=${waShxydm}&waGroup=${waGroup}`
    //   )}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`;
    // },
    subList(index, items, item) {
      // index1 == items.waGroup * 1"
      let arr = [];
      arr = item.filter((item1) => {
        if (index == item1.waGroup) {
          return item;
        }
      });
      return arr;
    },
    async getData() {
      this.info = JSON.parse(this.$route.query.item);
      this.waCompanyName = this.info.waCompanyName;
      let data = await getGroup();

      let data1 = await getAccountByOpenId({
        openId: localStorage.getItem("openid"),
      });
      this.data = data1.data;
      this.waShxydm = this.info.waShxydm;
      // console.log(nameList, '334');
      // data.data.forEach((title, index) => {

      // });
      let resultData = [];
      for (let i in data1.data) {
        let nameList = [];
        for (let i in data.data) {
          const title = data.data[i];
          let obj = {
            title: title,
            group: i,
          };
          nameList.push(obj);
        }
        const item = data1.data[i];
        nameList.forEach((subItem) => {
          const subList = [];
          item.forEach((items) => {
            if (subItem.group == items.waGroup) {
              subList.push(items);
            }
          });
          subItem.subList = subList;
        });

        let group = {
          title: i,
          nameList,
        };
        resultData.push(group);
        // arr=[
        //   titile:"",
        //   nameList:[
        //     {
        //       title:
        //       list
        //     }
        //   ]
        // ]
      }
      this.resultData = resultData.filter((item) => {
        return item.title == this.waCompanyName;
      });
      // console.log(this.resultData, "resultData");
    },
    onCopy(e) {
      // console.log(e.text, "成功");
      this.$toast.success("复制成功！");
      // this.request();
    },
    onError() {
      this.$toast.fail("复制失败！");
    },
    confirmBtn() {
      this.show2 = false;
      this.sharelink =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx47b46760287ffd31&redirect_uri=";
      if (this.phone && this.name) {
        this.getData();
        this.phone = "";
        this.name = "";
      }
    },
    // add(data, item, name) {
    //   console.log(data, item, name, "xxx");
    //   let titleArr = ["政务事务联络人", "知识产权联络人", "安全管理员"];
    //   this.title = titleArr[item - 1];
    //   this.show1 = true;
    //   let waShxydm = null;
    //   if (data && data[0]) {
    //     waShxydm = data[0].waShxydm;
    //   } else {
    //     // console.log(this.data);
    //     waShxydm = this.data[name][0].waShxydm;
    //   }
    //   const waGroup = item;
    //   this.sharelink = `${this.sharelink}${encodeURIComponent(
    //     `https://qtqyzx.qiantang.gov.cn/#/userCenter?isShare=true&waShxydm=${waShxydm}&waGroup=${waGroup}`
    //   )}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`;
    //   this.dataInfo = {
    //     waShxydm,
    //     waGroup: item,
    //     waCompanyName: name,
    //   };
    //   // localStorage.setItem('waShxydm',waShxydm );
    //   // localStorage.setItem('waGroup', item);
    // },
    cancelBtn() {
      this.show1 = false;
      this.phone = "";
      this.name = "";
    },
    confirm() {
      var reg_tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (
        this.phone.replace(/\s+/g, "").length == 0 ||
        isNaN(Number(this.phone)) ||
        !reg_tel.test(this.phone)
      ) {
        Toast.fail("请输入正确手机号");
        return false;
      }
      // let waShxydm = localStorage.getItem('waShxydm');
      // let waGroup = localStorage.getItem('waGroup');
      const { waShxydm, waCompanyName, waGroup } = this.dataInfo;
      let obj = {
        waShxydm: waShxydm,
        waCompanyName: waCompanyName,
        waAccountPhone: this.phone,
        waGroup: waGroup,
        waAccountName: this.name,
      };
      // console.log(obj);
      saveAccountByGroup(obj).then((res) => {
        // const that = this;
        if (res.success) {
          this.show1 = false;
          // location.reload();

          this.show2 = true;
          // this.data = res.data || {};

          // Toast.success({
          //   forbidClick: true,
          //   message: "添加成功",
          //   onClose() {
          //     that.$router.push({
          //       path: "/userCenter",
          //     });
          //   },
          // });
        } else {
          if (res.actionMessages && res.actionMessages[0]) {
            const msg = res.actionMessages[0];
            this.$toast.fail(msg);
          }
        }
      });
    },
    request(data, waShxydm, waCompanyName) {
      // console.log(data, waShxydm, "222222222");
      // let url = location.href.split("#")[0];
      let url = location.href.split("#")[0];
      // console.log(location.href, "222");
      let linkUrl =
        location.href.split("?")[0] +
        `?isShare=true&name=${encodeURIComponent(
          this.$store.state.globalInfo.nickname
        )}&waShxydm=${waShxydm}&waCompanyName=${encodeURIComponent(
          waCompanyName
        )}&waGroup=${data.group}`;
      // console.log(url, "3333");
      // console.log(linkUrl, "3333");
      checkSign({ waGroup: url }).then((res) => {
        // console.log(res, "55555");
        // eslint-disable-next-line no-undef
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: "wx47b46760287ffd31", // 必填，公众号的唯一标识
          timestamp: res.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
          signature: res.data.signature, // 必填，签名
          jsApiList: [
            "updateTimelineShareData",
            "updateAppMessageShareData",
            "onMenuShareAppMessage",
            "onMenuShareTimeline",
          ], // 必填，需要使用的JS接口列表
        });
        // eslint-disable-next-line no-undef
        wx.ready(() => {
          // eslint-disable-next-line no-undef
          wx.checkJsApi({
            jsApiList: ["updateTimelineShareData", "updateAppMessageShareData"],
          });

          // eslint-disable-next-line no-undef
          wx.updateAppMessageShareData({
            title: `${this.$store.state.globalInfo.nickname}邀请你加入${data.title}群组`, // 分享标题
            desc: "", // 分享描述
            // link: `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx47b46760287ffd31&redirect_uri=${encodeURIComponent(
            //   `http://qtqyzx.qiantang.gov.cn/#?isShare=true&waShxydm=${waShxydm}&waGroup=${group}`
            // )}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            link: linkUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: require("../../assets/images/userCenter/y.png"), // 分享图标
            // imgUrl: "", // 分享图标
            success: function (res) {
              // 设置成功
              // console.log("成功");
              // console.log(res, 4444);
              // this.imgShow = false;
            },
          });
        });
      });
    },
    add(data) {
      this.imgShow = true;
      this.request(data, this.waShxydm, this.waCompanyName);
    },
  },
};
</script>

<style lang="scss" scoped>
.back {
  color: #fff;
  font-size: rem(28);
  position: absolute;
  display: block;
  padding: rem(2) rem(26);
  top: rem(20);
  left: rem(20);
  border: rem(1) solid #fff;
  border-radius: rem(30);
  z-index: 100;
}
.imgShow {
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.box-swipe {
  background: white;
  padding-top: 30px;
  border-radius: 32px 32px 0 0;
  position: relative;
  top: -55px;
  min-height: 55px;
}
.swiper-container {
  width: 375px;
  height: 300px;
  margin: 20px auto;
}
::v-deep .van-swipe__indicator {
  width: rem(40);
  height: rem(8);
  background-color: red;
  border-radius: 100%;
  background: #eeeeee;
  border-radius: rem(40);
  opacity: 1;
  -webkit-transition: opacity 0.2s, background-color 0.2s;
  transition: opacity 0.2s, background-color 0.2s;
}
::v-deep.van-cell--required::before {
  content: "";
  position: absolute;
  left: rem(36);
  top: rem(30);
  width: rem(6);
  height: rem(28);
  top: rem(40);
  background-image: linear-gradient(179deg, #2d90fb 0%, #84bfff 100%);
  border-radius: 40px;
}
.flex-block {
  display: block;
  padding-bottom: 0;
  ::v-deep.van-field__label {
    width: 100%;
    font-size: rem(28);
    color: #333333;
    margin-bottom: 5px;
    text-indent: 10px;
  }
}
.select-user {
  padding: rem(20) rem(30);
  display: flex;
  justify-content: space-between;
  span {
    font-size: rem(26);
    color: #666666;
    letter-spacing: 0;
  }
}
.select-user-item {
  padding: rem(20) rem(10);
  display: flex;
  flex-wrap: wrap;
  // justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 25%;
  span {
    font-size: rem(26);
    color: #666666;
    letter-spacing: 0;
  }
  p {
    width: 100%;
  }
}
.blue-font {
  color: #3295fa;
}
::v-deep .van-field__error-message {
  display: none;
}
.select-group {
  display: flex;
  flex-wrap: wrap;
  .tx {
    width: rem(88);
    height: rem(88);

    position: relative;

    span img {
      width: rem(28);
      height: rem(28);
    }
    .bind {
      position: absolute;
      right: 0;
      bottom: 0;
    }
    .no-bind {
      position: absolute;
      right: -9px;
      transform: translateX(-25%);
      bottom: -3px;
      img {
        border-radius: 0;
        width: rem(82);
        height: rem(24);
      }
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  p {
    text-align: center;
    margin: 5px 0;
  }
}
.card {
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.09);
  border-radius: 3px;
  margin: rem(20);
}
::v-deep .van-field__body {
  background: rgba(238, 238, 238, 0.6);
  border-radius: 4px;
  width: 100%;
  height: rem(66);
}
.select-user-header {
  padding: rem(20) rem(30);
  background: url("../../assets/images/userCenter/item-bg.png");
  background-size: 100% 100%;
  font-weight: bold;
  font-size: rem(36);
  color: white;
  letter-spacing: -0.72px;
}
.banner {
  padding: rem(20);
  width: 100%;
  height: rem(394);
  background: url("../../assets/images/userCenter/s.png");
  background-size: 100%;
  color: white;
  .title {
    color: #fff;
    font-size: rem(40);
  }
  h3 {
    font-size: rem(48);
  }
  p {
    font-size: rem(36);
    margin-top: rem(10);
  }
  img {
    width: rem(36);
    height: rem(36);
  }
}
.van-cell {
  font-size: 14px;
  color: black;
  padding-top: rem(30);
  padding-bottom: rem(30);
  span {
    font-family: DIN-Bold;
    font-weight: Bold;
    font-size: rem(56);
    color: #3296fa;
    vertical-align: middle;
    margin-right: rem(10);
  }
}
.van-cell img {
  width: rem(64);
  vertical-align: middle;
  height: rem(64);
}

.uesrCenter {
  .link-con {
    display: flex;
    padding: 0 rem(20);
    justify-content: center;
    align-items: center;
    .link-txt {
      margin-right: rem(20);
      word-break: break-all;
      max-width: rem(400);
    }
  }
  ::v-deep .van-dialog__header {
    font-size: rem(32);
    // font-weight: bolder;
  }
}

.pop-banner {
  display: flex;
  align-items: center;

  ::v-deep .van-field__body {
    width: 250px;
    border: none !important;
    margin-left: 5px;
    background: none;
    position: relative;
    &::before {
      display: block;
      content: "";
      width: 220px;
      position: absolute;
      height: 2px;
      background: white;
      top: 30px;
      left: -25px;
    }
  }

  .van-cell {
    padding: 0;
    background: none;

    display: block !important;
  }
  ::-webkit-input-placeholder {
    color: white !important;
  }
  .van-cell--required::before {
    display: none;
  }
}
</style>
