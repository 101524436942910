/*
 * @Author: 陈乾
 * @Date: 2021-12-20 17:38:03
 * @LastEditors: 陈乾
 * @LastEditTime: 2021-12-20 22:58:04
 * @Description: file content
 * @FilePath: /钱塘新区企业认领公众号/src/api/user.js
 */
import request from '../utils/request.js'
// 授权管理企业信息列表接口
export function SupervisionCount(data) {
  return request({
    method: 'post',
    url: '/public/message/getWechatEnterpriseSupervisionCount',
    params: data
  })
}

//授权管理用户管理分类
export function getGroup(data) {
  return request({
    method: 'post',
    url: '/public/message/getGroup',
    params: data
  })
}

//授权管理用户管理分类
export function getAccountByOpenId(params) {
  return request({
    method: 'post',
    url: '/public/message/getAccountByOpenId',
    params: params
  })
}
